define("ember-svg-jar/inlined/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M12.15 23.25H1.75a1 1 0 01-1-1V1.75a1 1 0 011-1h20.5a1 1 0 011 1v20.5a1 1 0 01-1 1h-6.1v-8.5h2.56a.5.5 0 00.5-.44l.38-3a.5.5 0 00-.5-.56h-2.94V9.69A1.69 1.69 0 0117.84 8h1.81a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5h-1.81a5.69 5.69 0 00-5.69 5.69v1.06h-2.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h2.5v8.5z\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
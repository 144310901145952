define("ember-svg-jar/inlined/snapchat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M12 23.25a4.8 4.8 0 01-3-1.67 2.91 2.91 0 00-2.54-.81l-.64.11a1.31 1.31 0 01-1.52-1.62.84.84 0 00-.58-1l-2-.41a.85.85 0 01-.11-1.59 12.196 12.196 0 002.57-1.44 1.36 1.36 0 00-.09-2.24l-1.23-.78a1.52 1.52 0 01-.46-2.1 1.6 1.6 0 012.17-.45l1.32.84v-3.2a6.14 6.14 0 0112.27 0v3.19l1.32-.84a1.6 1.6 0 012.17.45 1.52 1.52 0 01-.46 2.1l-1.23.78a1.36 1.36 0 00-.09 2.24 12.195 12.195 0 002.57 1.44.85.85 0 01-.11 1.59l-2 .41a.84.84 0 00-.58 1 1.31 1.31 0 01-1.49 1.61l-.64-.11a2.91 2.91 0 00-2.54.81A4.8 4.8 0 0112 23.25z\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/discord-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M22.7 11.2l-.2-1.9-.6-2.4c-.3-1.2-.7-2.4-1.4-3.5A7.2 7.2 0 0016.3.3L14.8 0c-.2 0-.4.1-.5.3l1.7.6.6.3c.8.4 1.6.8 2.2 1.4 0 0 .1 0 0 0l-.6-.1c-.6-.2-1.1-.5-1.7-.6a17.4 17.4 0 00-12.8.8c.6-.6 1.4-1 2.2-1.4l2.5-1h-1C4.5.9 2.7 2.5 1.6 5 1 6.4.6 8 .3 9.4c-.1.8-.3 1.7-.3 2.6.1 1 .5 1.8 1.4 2.5 1.3 1 2.7 1.3 4.3 1.4l.2-.1L7 14.3c-1.3-.4-2.4-1-3.3-2H4l.3.3c1.2.5 2.4 1 3.7 1.2 1.7.5 3.5.7 5.3.5 1.8-.2 3.5-.9 5.2-1.7l.3-.2-.1.3c-.8.7-1.8 1.3-2.9 1.6-.2.1-.2.1 0 .3l.9 1.2.4.2c1.6 0 3-.5 4.2-1.3 1-1.3 1.5-2.2 1.5-3.5zM8 10.9c-.8 0-1.4-.2-2-.8v-.4c-.2-.5-.3-1-.1-1.5a2 2 0 012-1.4c.8 0 1.5.6 1.8 1.4l.1.3h.1C9.7 9.7 9 10.7 8 11zm1.7-2.4l.2-.2-.2.2zm5.1 2.4c-1 0-2-1-2-2 0-1.1 1-2.1 2-2.1 1.1 0 2 1 2 2a2 2 0 01-2 2.1z\" fill=\"#90A09D\"/>",
    "attrs": {
      "width": "23",
      "height": "16",
      "viewBox": "0 0 23 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/reddit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M13 21.69c5.13 0 9.29-2.847 9.29-6.36S18.13 8.97 13 8.97c-5.13 0-9.29 2.848-9.29 6.36 0 3.513 4.16 6.36 9.29 6.36z\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M16.46 18.15A7 7 0 0113 19a7 7 0 01-3.48-.87m8.14-4.26a.75.75 0 11-1.5 0 .75.75 0 011.5 0m-7.82 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0m9.76-3.02a2.45 2.45 0 112.58 3.47\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M20.53 6.61a2.15 2.15 0 100-4.3 2.15 2.15 0 000 4.3z\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M6.4 10.86a2.45 2.45 0 10-2.58 3.47M13 9s0-6.67 5.38-4.91\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "26",
      "height": "24",
      "viewBox": "0 0 26 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\"1.74\" y=\".75\" width=\"22.5\" height=\"22.5\" rx=\"6\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M12.99 17a5 5 0 100-10 5 5 0 000 10z\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M20.34 5.15a.5.5 0 01-1 0m0 0a.5.5 0 011 0\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
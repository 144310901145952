define("ember-svg-jar/inlined/medium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M1.74.75h22.5v22.5H1.74z\"/><path clip-rule=\"evenodd\" d=\"M9.56 18.36H4.92l2.28-3.09 2.36 3.09z\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M13.75 14.41L9.88 5.64H5.17V6C8.44 9.95 7.42 8.28 12 18.36h.48l4-12.72h4.33V6L19.5 7.21a.37.37 0 00-.14.35v8.87a.37.37 0 00.14.35l1.32 1.57h-6.15l1.14-1.6c.13-.13.13-.16.13-.35V7.6M7.2 15.27V8.48\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
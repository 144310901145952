define("ember-svg-jar/inlined/telegram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M23.53.966A1.45 1.45 0 0022.098.66L.995 7.615c-.575.19-.965.703-.993 1.308a1.439 1.439 0 00.866 1.395l5.219 2.283 1.574 7.303c.066.304.22.574.546.657.33.084.563-.096.81-.28l3.872-2.9 4.528 3.705a1.44 1.44 0 001.382.25c.477-.161.83-.55.945-1.04l4.218-17.93a1.45 1.45 0 00-.432-1.4zM9.354 13.91a.177.177 0 00-.008.021l-.873 3.05-.974-4.518 6.7-3.722-4.685 4.897a.705.705 0 00-.16.272zm.277 4.15l.396-1.386.38-1.325 1.358 1.112-2.134 1.599zM22.589 2.043l-4.217 17.93c-.002.01-.005.021-.025.028-.02.007-.03 0-.038-.006l-4.954-4.055-2.296-1.878 7.375-7.711a.705.705 0 00-.852-1.103l-10.92 6.067-5.229-2.288c-.016-.007-.025-.011-.023-.038 0-.027.01-.03.026-.035L22.54 1.997c.01-.003.022-.007.039.009.017.015.014.027.011.037z\" fill=\"#7C8CA9\"/>",
    "attrs": {
      "width": "24",
      "height": "22",
      "viewBox": "0 0 24 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en-us", {
    "buy": "How to Buy",
    "buy.desc": "<p class=\"section-text\">FYP inu currently runs only on the Ethereum Network, which means it can be best purchased and sold through <b>UniSwap</b></p><p class=\"section-text\">FYP inu is a decentralized experiment project. As such we always recommend the use of DEXs. If you choose to utilize a CEX instead, remember to research it first to ensure it is both safe and secure.</p>",
    "buy.guide": "HOW TO BUY",
    "buy.guide.connectUniswap": "Connect your wallet to UniSwap",
    "buy.guide.connectUniswap.desc": "Connect your wallet to UniSwap by clicking ‘Connect wallet’ and selecting NekoMask/MetaMask",
    "buy.guide.createWallet": "Create NekoMask/MetaMask wallet",
    "buy.guide.createWallet.desc": "Create a NekoMask/MetaMask Wallet using either a desktop computer or an iOS/Android mobile device. That will allow you to buy, sell, send, and receive your FYP inu tokens",
    "buy.guide.sendEth": "Send ETH to your wallet",
    "buy.guide.sendEth.desc": "You can buy Ethereum (ETH) directly on NekoMask/MetaMask or transfer it to your NekoMask/MetaMask Wallet from exchanges like Bitmart, Ethereum, etc",
    "buy.guide.swap": "Swap ETH for FYP inu",
    "buy.guide.swap.desc": "You can start swapping as soon as you have ETH available! Press ‘Select a token’ and enter the token address or search for it on the tokens list.",
    "cl.about": "About",
    "cl.buy": "Buy on Uniswap",
    "cl.buy.dextools": "Buy on Dextools",
    "cl.buy.uniswap": "Buy on UniSwap",
    "cl.buyHodl": "BUY NOW & ENTER",
    "cl.buyNow": "Buy Now",
    "cl.chart": "Chart",
    "cl.connectWallet": "Connect Wallet",
    "cl.dextools": "Dextools",
    "cl.footer.links.ethereum": "Ethereum",
    "cl.footer.links.medium": "Medium",
    "cl.getStarted": "Get Started",
    "cl.howItWorks": "How it Works",
    "cl.howToBuy": "Buy FYP inu",
    "cl.integration": "Integration",
    "cl.loading": "Loading...",
    "cl.logoTitle": "FYP inu",
    "cl.nftMint": "NFT Mint",
    "cl.rewards": "View Rewards",
    "cl.roadmap": "Roadmap",
    "cl.staking": "Staking",
    "cl.title": "FYP inu - Create to earn",
    "cl.tokenomics": "Tokenomics",
    "cl.whitepaper": "WhitePaper",
    "donations": "Donations",
    "donations.funds": "Where do the funds go?",
    "donations.funds.points": "<ul><li>The Plant Dao will be making donations to environmental protection organizations.</li><li>Development of TREE game assets to be used across open worlds in multiple game engines.</li><li>Last but not least, The Dao will vote and decide which environmental organization to donate to.</li></ul>",
    "donations.proposals.choices.no": "No",
    "donations.proposals.choices.yes": "Yes",
    "donations.proposals.ends": "Ends {time}",
    "donations.proposals.noContent": "There are currently no donation proposals to display",
    "donations.proposals.state.active": "Active",
    "donations.proposals.state.closed": "Closed",
    "donations.proposals.state.core": "Core",
    "donations.proposals.state.pending": "Pending",
    "donations.proposals.viewAll": "View all proposals",
    "donations.proposals.votes": "{votes} Votes",
    "footer.about": "About",
    "footer.about.partnerships": "Partnerships & Affiliates",
    "footer.about.roadmap": "Roadmap",
    "footer.about.tokenomics": "Tokenomics",
    "footer.about.whitepaper": "Whitepaper",
    "footer.cookies": "Cookie Policy",
    "footer.copyright": "&copy; 2022 FYP inu All rights reserved.",
    "footer.newsletter": "Newsletter",
    "footer.privacy": "Privacy Policy",
    "footer.products": "Products",
    "footer.products.marketplace": "NFT Marketplace",
    "footer.support": "Support",
    "footer.support.contact": "Contact Us",
    "footer.support.faq": "FAQ",
    "footer.terms": "Terms of Use",
    "tokenomics": "Tokenomics",
    "tokenomics.buy-tax": "Buy Taxes",
    "tokenomics.liquidity": "Liquidity",
    "tokenomics.max-wallet": "Max Wallet",
    "tokenomics.prize-pool": "Prize Pool",
    "tokenomics.sell-tax": "Sell Taxes",
    "tokenomics.team": "Team",
    "tokenomics.total-supply": "Total Supply"
  }]];
  _exports.default = _default;
});
define("ember-svg-jar/inlined/twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M23.18 6.71l-2.1-.9 1.1-2.4-2.56.85a4.47 4.47 0 00-3-1.2 4.51 4.51 0 00-4.5 4.5v1c-3.54.73-6.63-1.2-9.5-4.5-.5 2.667 0 4.667 1.5 6l-3.29-.5a4.55 4.55 0 004.25 4l-2.75 1c1 2 2.82 2.31 5.25 2.5a11.52 11.52 0 01-6.75 2c12.76 5.67 20.25-2.66 20.25-10v-.83l2.1-1.52z\" stroke=\"#7C8CA9\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/telegram-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 7.3l.8-.7 3.8-1.5L18 .1h.3c.6-.2 1 .2 1 .8 0 .4 0 .8-.2 1.2a1353.9 1353.9 0 01-3 13.5c-.1.3-.5.5-.9.3l-.6-.2-4-3c-.2 0-.2-.1-.3-.2l-.1.2-2.1 2a1 1 0 01-.8.3l.1-.8c0-1.2.2-2.4.3-3.5l.1-.3 8-7.1.1-.4h-.3L15 3 5.2 9.3H5a145 145 0 01-5-1.8v-.2z\" fill=\"#90A09D\"/>",
    "attrs": {
      "width": "20",
      "height": "16",
      "viewBox": "0 0 20 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/twitter-fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 4l1.9-2c.3-.3 0-.9-.4-.8l-2.3.3A4.2 4.2 0 0014 0a4 4 0 00-3.7 5.5c-3-.1-6.2-.7-7.8-3.9-.2-.4-.8-.4-1 0-1.1 3.9.9 9 5.5 10.4-1.1 1.1-4.1 1.4-6.4 1.2-.5 0-.8.5-.4.8 2 1.7 4.8 2 7.3 2 6.5 0 11-5.5 10.5-12z\" fill=\"#90A09D\"/>",
    "attrs": {
      "width": "21",
      "height": "16",
      "viewBox": "0 0 21 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});